import { StyledFieldError } from '@/components/uielements/form-fields/styles';
import Label from '@/components/uielements/label';
import { StyledSelectContainer } from '@/components/uielements/selectInput/styles';

interface Props {
  disabled?: boolean;
  errorMessage?: string;
  name: string;
  onChange: (value: string) => void;
  options: { label: string; value: string }[];
  placeholder?: string;
  title: string;
  value: string;
}
export const Select = ({
  options,
  onChange,
  title,
  name,
  placeholder,
  value,
  errorMessage,
  disabled,
}: Props) => {
  return (
    <div style={{ position: 'relative' }}>
      <StyledSelectContainer>
        <div className="select-style">
          <Label visuallyHidden={true} htmlFor={name}>
            {placeholder || name}
          </Label>
          <select
            name={name}
            id={name}
            onChange={(e) => onChange(e.target.value)}
            value={value}
            disabled={disabled}
          >
            {title && (
              <option value="" disabled={true}>
                {title}
              </option>
            )}
            {options.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </StyledSelectContainer>
      <StyledFieldError>{errorMessage}</StyledFieldError>
    </div>
  );
};
